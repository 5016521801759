import React, { Component } from 'react';
import QrReader from './QrReader';

class App extends Component {
  state = {
    result: 'No result'
  };

  showViewFinder = true;
  handleScan = data => {
    if (data) {
      this.didScan = true;
      const info = this.handleData(data);
      this.getStaticImage();
      this.reader.stopCamera();
      this.setState({
        result: info
      });
    }
  };

  handleError = err => {
    console.error(err);
  };

  handleData = data => {
    //regexp to find links in the qr-string
    const url = data.match(
      new RegExp(
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
      )
    );
    console.log(url);
    if (url) {
      return url;
    } else {
      return data;
    }
  };

  getStaticImage() {
    const input = this.reader.els.preview;
    const canvas = this.canvas;
    const context = canvas.getContext('2d');

    // Draw the video frame to the canvas.
    context.drawImage(input, 0, 0, canvas.width, canvas.height);
  }

  render() {
    let scanElement = (
      <QrReader
        ref={elem => (this.reader = elem)}
        delay={300}
        onError={this.handleError}
        onScan={this.handleScan}
        style={{ width: '100%' }}
        facingMode="user"
      />
    );

    if (this.didScan) {
      scanElement = <p></p>;
    }

    return (
      <div>
        {scanElement}
        {this.state.result && (
          <div style={{ position: 'absolute', width: '100%' }}>
            <p style={{ transform: 'translate(50%, 50%)', color: '#FFF', fontSize: 24 }}>{this.state.result}</p>
          </div>
        )}
        <canvas
          ref={elem => (this.canvas = elem)}
          width="500"
          height="400"
          style={{ width: '100%' }}
        ></canvas>
      </div>
    );
  }
}

export default App;
